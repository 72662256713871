import React, { useMemo } from 'react';

import { UploadRequests } from '@/services/api/requests/Upload';
import DefaultAvatar from '@/assets/DefaultAvatar.svg';

import { EAthletePosition, EGameType } from '@/pages/AthleteCard/types';
import { getAthleteShortPosition } from '@/pages/AthleteCard/utils';
import {
  AthleteInfoContainer,
  AthleteNameContainer,
  CardOptionsContainer,
  Container,
} from './styles';
import { ICardProps } from './types';

const Card: React.FC<ICardProps> = ({ athlete, cardGame }) => {
  const { goalOptions, statisticOptions } = cardGame;
  const { name, number, position, photo: athletePhoto } = athlete._athlete;
  const { name: teamName, abbrev, image: teamImage } = athlete._team;

  function getAthleteScoreLabel(): string {
    if (!position) {
      return 'Quantidade';
    }
    if (position === EAthletePosition.GOALKEEPER) {
      return 'Quantidade de defesas';
    }
    if (
      position === EAthletePosition.ATP ||
      position === EAthletePosition.WTA
    ) {
      return 'Quantidade de sets';
    }
    if (position === EAthletePosition.F1_START) {
      return 'Grid de largada';
    }
    if (position === EAthletePosition.F1_FINISH) {
      return 'Linha de chegada';
    }
    if (position === EAthletePosition.POKER__HAND_CHAMPION_SEQUENCE) {
      return 'Hand Champion Sequence';
    }
    if (position === EAthletePosition.POKER__ELIMINATION_SEQUENCE) {
      return 'Elimination Sequence';
    }
    if (position === EAthletePosition.GOAL_MINUTE) {
      return 'Minuto do gol';
    }
    if (position === EAthletePosition.FIRST_GOAL_MINUTE) {
      return 'Minuto do 1˚ gol da partida';
    }
    if (position === EAthletePosition.SECOND_GOAL_MINUTE) {
      return 'Minuto do 2˚ gol da partida';
    }
    if (position === EAthletePosition.FIRST_GOAL_MINUTE_OF_THE_ROUND) {
      return 'Minuto do 1˚ gol da rodada';
    }
    if (position === EAthletePosition.SECOND_GOAL_MINUTE_OF_THE_ROUND) {
      return 'Minuto do 2˚ gol da rodada';
    }
    if (position === EAthletePosition.FIRST_HALF_GOAL_MINUTE) {
      return 'Minuto do gol no 1˚ tempo';
    }
    if (position === EAthletePosition.SECOND_HALF_GOAL_MINUTE) {
      return 'Minuto do gol no 2˚ tempo';
    }
    if (position === EAthletePosition.GOAL_INSIDE_AREA) {
      return 'Gols dentro da área na partida';
    }
    if (position === EAthletePosition.GOAL_OUTSIDE_AREA) {
      return 'Gols fora da área na partida';
    }
    if (position === EAthletePosition.GOAL_INSIDE_AREA_OF_THE_ROUND) {
      return 'Gols dentro da área na rodada';
    }
    if (position === EAthletePosition.GOAL_OUTSIDE_AREA_OF_THE_ROUND) {
      return 'Gols fora da área na rodada';
    }
    if (position === EAthletePosition.GOALS_IN_THE_FIRST_HALF) {
      return 'Quantidade de gols no 1˚ tempo';
    }
    if (position === EAthletePosition.GOALS_IN_THE_SECOND_HALF) {
      return 'Quantidade de gols no 2˚ tempo';
    }
    if (position === EAthletePosition.GOALS_IN_THE_FIRST_HALF_OF_THE_ROUND) {
      return 'Quantidade de gols no 1˚ tempo da rodada';
    }
    if (position === EAthletePosition.GOALS_IN_THE_SECOND_HALF_OF_THE_ROUND) {
      return 'Quantidade de gols no 2˚ tempo da rodada';
    }

    if (position === EAthletePosition.FOOTBALL__FIELD_GOAL_AMOUNT) {
      return 'Quantidade de "Field Goal"';
    }
    if (position === EAthletePosition.FOOTBALL__TOUCHDOWN_AMOUNT) {
      return 'Quantidade de "Touchdown"';
    }
    if (position === EAthletePosition.FOOTBALL__TWO_POINT_CONVERSION_AMOUNT) {
      return 'Quantidade de conversões de 2 pontos';
    }
    if (position === EAthletePosition.FOOTBALL__EXTRA_KICK_AMOUNT) {
      return 'Quantidade de chutes extra';
    }
    if (position === EAthletePosition.FOOTBALL__SAFETY_AMOUNT) {
      return 'Quantidade de "Safety"';
    }
    if (position === EAthletePosition.FOOTBALL__PICK_SIX_TOUCHDOWN_AMOUNT) {
      return 'Quantidade de Pick-six "touchdown"';
    }

    return 'Quantidade de gols';
  }

  const formattedPosition = getAthleteShortPosition(position);
  const athleteScoreLabel = getAthleteScoreLabel();

  const athleteGoalOptions = useMemo(() => {
    return athlete._athleteGoals.map(optionId =>
      goalOptions.find(option => option._id === optionId),
    );
  }, [athlete._athleteGoals, goalOptions]);

  const athleteStatisticOptions = useMemo(() => {
    return athlete._athleteStatistics.map(optionId =>
      statisticOptions.find(option => option._id === optionId),
    );
  }, [athlete._athleteStatistics, statisticOptions]);

  return (
    <Container>
      <AthleteInfoContainer>
        <div>
          {(number || formattedPosition) && (
            <h4>
              {number}
              <span>{formattedPosition}</span>
            </h4>
          )}
        </div>
        <img
          src={
            athletePhoto
              ? UploadRequests.getFileUrl(athletePhoto)
              : DefaultAvatar
          }
          alt={name}
        />
        <div>
          <div>
            <img src={teamImage} alt={teamName} />
            <p>{abbrev}</p>
          </div>
        </div>
      </AthleteInfoContainer>
      <AthleteNameContainer>
        <h6>{name}</h6>
      </AthleteNameContainer>
      {cardGame.gameType === EGameType.OPTIONS && (
        <CardOptionsContainer>
          <div>
            <strong>Estatísticas</strong>
            {athleteStatisticOptions.length > 0 ? (
              <ul>
                {athleteStatisticOptions.map(option => (
                  <li>{option?.name}</li>
                ))}
              </ul>
            ) : (
              <small>Aguarde os resultados</small>
            )}
          </div>
          <div>
            <strong>Gols</strong>
            {athleteGoalOptions.length > 0 ? (
              <ul>
                {athleteGoalOptions.map(option => (
                  <li>{option?.name}</li>
                ))}
              </ul>
            ) : (
              <small>Aguarde os resultados</small>
            )}
          </div>
        </CardOptionsContainer>
      )}
      {cardGame.gameType === EGameType.CLASSIC && (
        <CardOptionsContainer>
          <div>
            <strong>{athleteScoreLabel}</strong>
            <h6>{athlete.athleteScore}</h6>
          </div>
        </CardOptionsContainer>
      )}
    </Container>
  );
};

export default Card;
