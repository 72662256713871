import axios from 'axios';

import Environment from '@/config/Environment';

const api = axios.create({
  baseURL: Environment.REACT_APP__API_URL,
});

api.interceptors.request.use(config =>
  process.env.NODE_ENV === 'development'
    ? new Promise(resolve => setTimeout(() => resolve(config), 500))
    : config,
);

export default api;
