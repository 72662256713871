declare global {
  interface Window {
    env?: IEnvironmentVars;
  }
}

export interface IEnvironmentVars {
  REACT_APP__BASE_URL: string;
  REACT_APP__API_URL: string;
  REACT_APP__SOCKET_URL: string;
}

export const env = { ...process.env, ...window.env };

const environment: IEnvironmentVars = {} as IEnvironmentVars;

const isUrl = (url?: string): boolean => {
  if (!url) return false;

  const regex = new RegExp(/^(http|https):\/\/[^ "]+$/);
  return regex.test(url);
};

if (env.NODE_ENV === 'development') {
  environment.REACT_APP__BASE_URL = 'http://localhost:3000';
  environment.REACT_APP__API_URL = 'http://localhost:8000';
  environment.REACT_APP__SOCKET_URL = 'http://localhost:8000';
} else if (env.NODE_ENV === 'production') {
  const {
    REACT_APP__BASE_URL: BASE_URL,
    REACT_APP__API_URL: API_URL,
    REACT_APP__SOCKET_URL: SOCKET_URL,
  } = env;

  environment.REACT_APP__BASE_URL = isUrl(BASE_URL) && BASE_URL ? BASE_URL : '';
  environment.REACT_APP__API_URL = isUrl(API_URL) && API_URL ? API_URL : '';
  environment.REACT_APP__SOCKET_URL =
    isUrl(SOCKET_URL) && SOCKET_URL ? SOCKET_URL : '';
}

export default environment;
