import { ApiResponse } from '@/services/api/types';
import api from '@/services/api';
import Env from '@/config/Environment';

import { IUploadRequestFormData, IUploadResponse } from './types';

function upload(params: IUploadRequestFormData): ApiResponse<IUploadResponse> {
  const formData = new FormData();
  formData.append('file', params.file);
  formData.append('from', params.from);

  return api.post<IUploadResponse>('/api/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

function getFileUrl(file: string): string {
  return `${Env.REACT_APP__API_URL}/api/file/${file}`;
}

export const UploadRequests = {
  upload,
  getFileUrl,
};
